<template>
    <topMenu />
    <section class="blockElement fiveColumn pt-3 tab-content indexTop">
        <div class="container-fluid">
            <div class="d-flex justify-content-between flex-wrap mt-3 pb-1 mb-4">
                <div class="d-flex align-items-center">
                    <h1 class="d-flex align-items-center bold f-30 mb-0 line-1 tag">
                        <v-lazy-image height="23" width="28" class="me-2" src="/assets/images/Leaders-icon.png" alt="Leaders" /> {{$t('top_traders.leaderText')}}
                    </h1>
                    <div class="tooltipbutton d-flex ms-2">
                        <span class="tooltiptext">
                            <p class="mb-2 medium line-4 f-17">{{$t('top_traders.WhatLeaders')}}</p>
                            <p class="mb-0 line-4 f-15">{{$t('top_traders.LeadersDec')}}</p>
                        </span>
                        <p class="f-12 d-flex align-items-center justify-content-center mb-0"> <vue-feather class="smallSize" type="alert-circle"></vue-feather></p>
                    </div>
                </div>
                <router-link to="/compare" class="button zulu_btn compare_btn rounded border-button ps-1 dark-text" :class="{'disabled' : store.customerDetail?.readOnly}">
                    <v-lazy-image src="/assets/images/orange-plus.svg" :alt="$t('compare_leaders.text1')" :title="$t('compare_leaders.text1')" class="me-2" />
                    {{$t('compare_leaders.addLeaderstoCompare')}}
                </router-link>
            </div>
        </div>
        <div class="container-fluid mb-4 pb-md-4" v-show="store.leaderWatchList.length">
            <WatchlistWidget :watchListType="'LEADER'" :key="watchKey"></WatchlistWidget>
        </div>
    </section>
    <section id="tab1" class="blockElement fiveColumn space overflow-hidden pt-0 tab-content"
        v-if="store.topTrdaersList.length > 0">
        <div class="container-fluid mb-4">
            <div class="row align-items-center g-0 mb-4">
                <div class="col-12">
                    <div class="d-flex align-items-center mb-1">
                        <h6 class="mb-0 bold f-22 me-2">{{ store.topTrdaersList[0].name }}</h6>
                        <div class="tooltipbutton  mt-0 d-flex me-3">
                            <span class="tooltiptext f-12">
                                <p class="mb-0 line-4 f-14">{{store.topTrdaersList[0].descirption }}</p>
                            </span>
                            <p class="f-12 d-flex align-items-center justify-content-center mb-0"> <vue-feather class="smallSize" type="alert-circle"></vue-feather></p>
                        </div>
                        <router-link :to="'/traders/list/' + store.topTrdaersList[0].filterId" class="viewAll medium f-14 d-flex align-items-center">{{$t('top_traders.viewAll')}} <vue-feather class="ms-1" type="chevron-right" size="16"></vue-feather> </router-link>
                    </div>
                        
                    <!-- <p class="f-12 gray mb-0">{{ store.topTrdaersList[0].descirption }}</p> -->
                </div>
            </div>
            <div class="row newAddLeader"
                v-show="store.topTrdaersList?.[0] && store.topTrdaersList[0].result && store.topTrdaersList[0].result.length > 0">
                <div class="col-12">
                    <carousel class="mt-0" :settings="carousleSettings" :breakpoints="breakpoints">
                        <slide v-for="item, index in  store.topTrdaersList[0].result.slice(0,20)" :key="index">
                            <div class="card h-100 w-100 overflow-hidden" v-show="item.trader && Object.keys(item.trader).length">
                                <div class="card-body px-0 pb-0"
                                    v-show="item.trader.profile && Object.keys(item.trader.profile).length > 0">
                                    <div class="text-center position-relative" v-show="item.trader.profile.name">
                                        <div class="vueRisk px-3 d-flex align-items-start justify-content-between">
                                            <p :title="static_vars.riskTooltip" class="bTn mb-0 f-12 bold uppercase" :class="[{'blueBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==1},{'greenBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==2},{'yellowBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==3},{'redBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==4},{'pinkBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==5}]" v-if="item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].hasOwnProperty('timeFrameRisk')">{{$t('top_traders.risk')}} {{item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk}}</p>
                                            <router-link :to="`/trader/${item.trader.profile.id}/trading?t=${Object.keys(item.trader.timeframeStats)[0]}&m=1`" class="circleImg d-inline-block mb-3">
                                                <v-lazy-image height="70" width="70" class="rounded-circle" :src="(!item.trader.profile.photoApproved) ? (item.trader.profile.customerAvatarUrl) ? item.trader.profile.customerAvatarUrl : static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + item.trader.profile.zuluAccountId + '&ignore=false' : static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + item.trader.profile.zuluAccountId + '&ignore=false'" :alt="item.trader.profile.name" :title="item.trader.profile.name" />
                                            </router-link>
                                            <div class="d-flex align-items-center">
                                                <a href="javascript:void(0)" class="me-1" :class="{'disabled' : store.customerDetail?.readOnly}" @click="AddWatchList(item)"><vue-feather type="star" size="19" :class="[{'filled' : isFollowed(item.trader.providerId)}]"></vue-feather></a>
                                                <div class="position-relative">
                                                <a href="javascript:void(0)" class="ms-1" @click="(opendots != item.trader.profile.zuluAccountId) ? opendots = item.trader.profile.zuluAccountId : opendots = -1">
                                                    <vue-feather size="20" type="more-vertical"></vue-feather>
                                                </a>
                                                <ul class="dropdown-menu" data-popper-placement="bottom-start" :class="[{ 'show': opendots ==  item.trader.profile.zuluAccountId }]">
                                                    <li><a class="f-12 dropdown-item" href="javascript:void(0);" @click="sharePop = item.trader.providerId">{{$t('top_traders.share')}}</a></li>
                                                    <li><router-link :to="{path:'/compare',query:{p:item.trader.profile.id}}" class="f-12 dropdown-item">{{$t('top_traders.compare')}}</router-link></li>
                                                </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="title">
                                            <router-link :to="`/trader/${item.trader.profile.id}/trading?t=${Object.keys(item.trader.timeframeStats)[0]}&m=1`">
                                                <h6 class="mb-1 f-16 ellipsTitle mx-auto" :title="item.trader.profile.name">{{ item.trader.profile.name }}</h6>
                                                <div class="element d-flex align-items-center justify-content-center mb-3">
                                                    <div class="element d-flex align-items-center justify-content-center me-2"
                                                        v-if="item.trader.overallStats.followers">
                                                        <p class="gray medium f-12 mb-0 me-1">{{$t('top_traders.copiers')}}</p>
                                                        <p class="f-12 bold mb-0">{{ item.trader.overallStats.followers || 0}}</p>
                                                    </div>
                                                    <div class="element d-flex align-items-center justify-content-center" v-if="item.trader.overallStats && item.trader.overallStats.includedInWatchlist">
                                                        <p class="gray medium f-12 mb-0 me-1">{{$t('top_traders.followers')}}</p>
                                                        <p class="f-12 bold mb-0">{{ item.trader.overallStats.includedInWatchlist || 0 }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </router-link>
                                            <div class="element"
                                                >
                                                <p class="mb-0 px-1 bold d-inline-flex" title="ROI" :class="ROIClass(item)" v-if="item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].hasOwnProperty('annualizedRorBasedRoi')">
                                                    {{parseInt(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi) >= 0 ? '+' : ''}}{{
                                                            parseFloat(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi).toFixed(2)
                                                            || 0
                                                    }}%</p>
                                            </div>
                                        </div>
                                        <div class="chartElement chartCalcHeight"
                                            :id="'topTraders' + item.trader.profile.zuluAccountId + '_' + store.topTrdaersList[0].order"
                                            style="width:100%;height: 126px;display:flex">
                                       <!--   <img src="/assets/images/undefinedchart.svg" /> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </slide>
                        <template #addons="{ slidesCount }">
                            <navigation v-if="slidesCount > 5"/>
                        </template>
                    </carousel>
                </div>
            </div>
        </div>
        <sharePop :shareURL="sharePop" :close="()=>sharePop = ''" v-if="sharePop" :shareType="'leaderProfile'"></sharePop>
        <hotPicks />
        <sectionThree ref="stepthree" />
        <sectionFour ref="lastsection" />
        <div class="container pb-3">
            <div class="row align-items-center">
                <div class="col-12 text-center">
                    <p class="mb-0">{{$t('top_traders.end_text')}}</p>
                </div>
            </div>
        </div>
    </section>
    <loginPopup v-if="showLoginPopup"></loginPopup>
</template>
<script>
import { myStore } from "@/store/pinia-store";
import topMenu from './topMenu'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import moment from 'moment'
import hotPicks from './leaders/hot-picks'
import sectionThree from './leaders/section-three'
import sectionFour from './leaders/section-four'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import sharePop from "@/views/social-feed/share-pop";
import WatchlistWidget from "@/views/trader/watchlist/watchlist-widget"
export default {
    setup() {
        const store = myStore();
        return { store };
    },
    data() {
        return {
            sharePop: '',
            showLoginPopup : false,
            opendots : false,
            errImage: [],
            carousleSettings: {
                itemsToShow: 6.5,
                itemsToScroll: 5,
                // mouseDrag: false,
                // touchDrag: false,
                snapAlign: 'start',
            },
            breakpoints: {
                320: {
                    itemsToShow: 1,
                    itemsToScroll:1,
                    snapAlign: 'start',
                },
                767: {
                    itemsToShow: 2.5,
                    itemsToScroll: 3,
                    snapAlign: 'start',
                },
                1024: {
                    itemsToShow: 3.5,
                    itemsToScroll: 4,
                    snapAlign: 'start',
                    mouseDrag: false,
                    touchDrag: false,
                },
                1199: {
                    itemsToShow: 4.5,
                    itemsToScroll: 5,
                    snapAlign: 'start',
                    mouseDrag: false,
                    touchDrag: false,
                },
                1499: {
                    itemsToShow: 6.5,
                    itemsToScroll: 6,
                    snapAlign: 'start',
                    mouseDrag: false,
                    touchDrag: false,
                },
            },
            watchKey: 0,
        };
    },
    components: {
        topMenu, hotPicks,sectionThree, sectionFour, Carousel, Slide, Navigation, WatchlistWidget, sharePop
    },
    methods: {
        isFollowed(id){
            if(this.store.leaderWatchList.length){
                let data = this.store.leaderWatchList.map(i => i.id)
                if(data.includes(parseInt(id))){
                    return true
                }else{
                    return false
                }

            }else{
                return false
            }
        },
        ROIClass(item){
            if(parseInt(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi) >= 0){
                return 'greenView'
            }else{
                return 'redView'
            }
        },
        callWatchList(){
            // this.store.getWatchList({},false,"1")
            this.store.getWatchList({},false,"180").then(res=>{ if(res) this.watchKey += 1 })
        },
        AddWatchList(item){
            if(!this.store.user.access_token){
                this.showLoginPopup = true
            }else{
                this.showLoginPopup = false
                if(this.isFollowed(item.trader.providerId)){
                    this.addWatchList(item,'DELETE')
                }else{
                    this.addWatchList(item,'ADD')
                }
            }
        },
        addWatchList(item,type){
            let json = {};
            if(type == 'ADD'){
                json['id'] = item.trader.providerId
                json['type'] = 'LEADER'
                json['name'] = item.trader.profile.name
                json['roi'] = 0
                json['chart'] = {}
                let data = this.store.leaderWatchList
                data.push(json)
                this.store.$patch({'leaderWatchList' : data})
            }else if(type == 'DELETE'){
                let data = this.store.leaderWatchList.filter(i => parseInt(i.id) != parseInt(item.trader.providerId))
                this.store.$patch({'leaderWatchList' : data})
            }
            let payload = {
                item: item.trader.providerId,
                type: 'LEADER'
            }
            this.store.addToWatchList(payload,false,this,type)
        },
        WatchListWidgetAddWatchList(item) {
            if (!this.store.user.access_token) {
                this.showLoginPopup = true;
            } else {
                this.showLoginPopup = false;
                if (this.isFollowed(item.id)) {
                    this.WatchListWidgetaddWatchListSub(item, "DELETE");
                } else {
                    this.WatchListWidgetaddWatchListSub(item, "ADD");
                }
            }
        },
        WatchListWidgetaddWatchListSub(item, type) {
            let payload = {
                item: item.id,
                type: "LEADER",
            };
            let json = {};
            if (type == "ADD") {
                json["id"] = item.id;
                json["type"] = "LEADER";
                json["currentPrice"] = item.currentPrice;
                json["dailyPriceChangePercentage"] = item.dailyPriceChangePercentage;
                json["description"] = item.description;
                json["name"] = item.name;
                json["chart"] = {};
                let data = this.store.leaderWatchList;
                data.push(json);
                this.store.$patch({ leaderWatchList: data });
            } else if (type == "DELETE") {
                let data = this.store.leaderWatchList.filter((i) => parseInt(i.id) != parseInt(item.id));
                this.store.$patch({ leaderWatchList: data });
            }
            this.store.addToWatchList(payload, false, this, type);
        },
        formatDate(date) {
            if (date) {
                let d = moment(date)
                let tz = -(new Date().getTimezoneOffset())
                d.add(tz, 'minutes')
                return moment(d).format('YYYY/MM/DD hh:mm')
            }
        },
        getTopTradersList() {
            this.store.callTopTradersList({}, true).then(() => {
                //console.log('==>', this.store.topTrdaersList[0])
                if (this.store.topTrdaersList.length){
                    if (Object.keys(this.store.topTrdaersList[0]).length) {
                        if (this.store.topTrdaersList[0].result && this.store.topTrdaersList[0].result.length > 0) {
                            this.store.topTrdaersList[0].result.slice(0, 20).forEach(item => {
                                if (item.trader && Object.keys(item.trader).length && item.trader.profile && Object.keys(item.trader.profile).length && item.trader.profile.zuluAccountId && item.trader.timeFrameGraph && item.trader.timeFrameGraph.series && item.trader.timeFrameGraph.series.length && item.trader.timeFrameGraph.series[0].data && item.trader.timeFrameGraph.series[0].data.length) {
                                    am5.ready(() => {
                                        this.tradersChart(item.trader.profile.zuluAccountId + '_' + this.store.topTrdaersList[0].order, item.trader.timeFrameGraph.series[0].data)
                                    });
                                }
                            })
                        }
                    }
                    this.$refs.stepthree.drawChart()
                    this.$refs.lastsection.drawChart()
                }
            })
        },
        tradersChart(id, chartdata) {
            //console.log(chartdata[chartdata.length-1])
            let chartId = 'topTraders' + id
            let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === chartId);
            d?.dispose();
            let root = am5.Root.new(chartId);

            root.numberFormatter.set("numberFormat", "#,###.00");

            root.setThemes([
                am5themes_Animated.new(root),
                am5themes_Responsive.new(root),
            ]);

            root.dateFormatter.setAll({
                dateFormat: "dd MMM yyyy",
                dateFields: ["valueX"],
            });

            // Create chart
            var chart = root.container.children.push(am5xy.XYChart.new(root, {
                focusable: true,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0,
            }));

            // Create axes
            let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
                visible: false,
                groupData: true,
                baseInterval: {
                    timeUnit: "day",
                    count: 1
                },
                renderer: am5xy.AxisRendererX.new(root, {
                    //minGridDistance: 10
                }),
                // tooltip: am5.Tooltip.new(root, {})
            }));

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                visible: false,
                strictMinMax: true,
                baseValue : this.getBaseValue(chartdata),
                renderer: am5xy.AxisRendererY.new(root, {})
            }));
            xAxis.get("renderer").grid.template.set("forceHidden", true);
            yAxis.get("renderer").grid.template.set("forceHidden", true);
            chart.get("colors").set("colors", [
                am5.color(0xff7200),
            ]);
            // Add series
            var series = chart.series.push(am5xy.SmoothedXLineSeries.new(root, {
                minBulletDistance: 10,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "y",
                valueXField: "x",
                tooltip: am5.Tooltip.new(root, {
                    // pointerOrientation: "horizontal",
                    labelText: "[bold]Date[/]: {valueX.formatDate('dd MMM yyyy')}  \n [bold]ROI(%)[/] : {valueY}"
                })
            }));

            series.fills.template.setAll({
                visible: true,
                fillOpacity: 0.1,
            });
            
            // series.fills.template.set("fillGradient", am5.LinearGradient.new(root, {
            //     stops: [{
            //         opacity: 0.1
            //     }, {
            //         opacity: 0.04
            //     }]
            // }));

            series.strokes.template.setAll({
                strokeWidth: 2,
            });

            // Set up data processor to parse string dates
            series.data.processor = am5.DataProcessor.new(root, {
                dateFormat: "yyyy-MM-dd",
                dateFields: ["x"]
            });

            // Add cursor
            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                // xAxis: xAxis,
                // behavior: "zoomX"
            }));
            cursor.lineY.set("visible", false);
            cursor.lineX.set("visible", false);

            // Set data
            var data = chartdata
            series.data.setAll(data);

            // Make stuff animate on load
            series.appear(300);
            chart.appear(300, 100);

        },
        getBaseValue(data){
            if(data.length){
                let value = data.map( i=> i.y);
                if(value.length){
                    // console.log(Math.min(...value))
                    return Math.min(...value)
                }else{
                    return 0
                }
            }
        }

    },
    created() {
        //this.store.$patch({'topTrdaersList' : {}})
        this.getTopTradersList()
    }
}
</script>