<template>
	<div class="container-fluid leaderUnderAmount mb-3 py-md-5">
        <div class="row g-3">
            <div class="col-12 col-lg-4 col-xl-3" v-if="store.topTrdaersList?.[3] && store.topTrdaersList[3].result && store.topTrdaersList[3].result.length">
                <div class="card-body vueCard element">
                    <div class="card-header bg-white flex-between">
                        <div class="d-flex align-items-center">
                            <p class="f-20 medium mb-0">
                                <!-- <v-lazy-image class="me-2" src="/assets/images/bxs_right-top-arrow-circle.png" alt="IconArrow" />  -->
                                <span class="elips" :title="store.topTrdaersList[3].name">{{store.topTrdaersList[3].name}}</span>
                            </p>
                            <div class="tooltipbutton mt-0 d-flex ms-2">
                            <span class="tooltiptext f-12">
                                <p class="mb-0 line-4 f-14">{{store.topTrdaersList[3].descirption }}</p>
                            </span>
                            <p class="f-12 d-flex align-items-center justify-content-center mb-0"> <vue-feather class="smallSize" type="alert-circle"></vue-feather></p>
                            </div>
                        </div>
                        <router-link class="roundArrow" :to="'/traders/list/' + store.topTrdaersList[3].filterId"><vue-feather
                                type="chevron-right"></vue-feather> </router-link>
                    </div>
                    <div class="flex-between px-3 mb-2" v-for="item,index in store.topTrdaersList[3].result.slice(0,5)" :key="index">
                        <div class="d-flex align-items-center" v-if="item.trader.profile && item.trader.profile">
                            <router-link :to="`/trader/${item.trader.profile.id}/trading?t=${Object.keys(item.trader.timeframeStats)[0]}&m=1`">
                            <span class="me-3 position-relative">
                                 <v-lazy-image width="40" height="40" class="rounded-circle" :src="(!item.trader.profile.photoApproved) ? (item.trader.profile.customerAvatarUrl) ? item.trader.profile.customerAvatarUrl : static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + item.trader.profile.zuluAccountId + '&ignore=false' : static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + item.trader.profile.zuluAccountId + '&ignore=false'" :alt="item.trader.profile.name" :title="item.trader.profile.name" />
                                <span :title="static_vars.riskTooltip" class="count position-absolute" :class="[{'blueBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==1},{'greenBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==2},{'yellowBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==3},{'redBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==4},{'pinkBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==5}]" v-if="item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].hasOwnProperty('timeFrameRisk')">{{item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk}}</span>
                            </span>
                        </router-link>
                            <div class="flex-shrink-0" v-if="item.trader.profile.name">
                                <router-link :to="`/trader/${item.trader.profile.id}/trading?t=${Object.keys(item.trader.timeframeStats)[0]}&m=1`"><p class="medium f-14 mb-0 roiTestSmall" :title="item.trader.profile.name" v-if="item.trader.profile.name">{{item.trader.profile.name}}</p></router-link>
                            </div>
                        </div>
                        <p class="mb-0 px-1 bold d-inline-flex" :class="ROIClass(item)" v-if="item.trader.timeframeStats && item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].hasOwnProperty('annualizedRorBasedRoi')">{{item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi >= 0 ? '+' : ''}}{{ parseFloat(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi).toFixed(2) || 0}}%</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 col-xl-3" v-if="store.topTrdaersList?.[1] && store.topTrdaersList[1].result && store.topTrdaersList[1].result.length">
                <div class="card-body vueCard element">
                    <div class="card-header bg-white flex-between">
                        <div class="d-flex align-items-center">
                            <p class="f-20 medium mb-0">
                                <!-- <v-lazy-image class="me-2" src="/assets/images/bxs_right-top-arrow-circle.png" alt="IconArrow" />  -->
                                <span class="elips" :title="store.topTrdaersList[1].name">{{store.topTrdaersList[1].name}}</span>
                            </p>
                            <div class="tooltipbutton mt-0 d-flex ms-2">
                            <span class="tooltiptext f-12">
                                <p class="mb-0 line-4 f-14">{{store.topTrdaersList[1].descirption }}</p>
                            </span>
                            <p class="f-12 d-flex align-items-center justify-content-center mb-0"> <vue-feather class="smallSize" type="alert-circle"></vue-feather></p>
                            </div>
                        </div>
                        <router-link class="roundArrow" :to="'/traders/list/' + store.topTrdaersList[1].filterId"><vue-feather
                                type="chevron-right"></vue-feather> </router-link>
                    </div>
                    <div class="flex-between px-3 mb-2" v-for="item,index in store.topTrdaersList[1].result.slice(0,5)" :key="index">
                        <div class="d-flex align-items-center" v-if="item.trader.profile && item.trader.profile">
                            <router-link :to="`/trader/${item.trader.profile.id}/trading?t=${Object.keys(item.trader.timeframeStats)[0]}&m=1`">
                            <span class="me-3 position-relative">
                                <v-lazy-image width="40" height="40" class="rounded-circle"
                                    :src="static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + item.trader.profile.zuluAccountId + '&ignore=false'"
                                    :alt="item.trader.profile.name" :title="item.trader.profile.name" />
                                <span :title="static_vars.riskTooltip" class="count position-absolute" :class="[{'blueBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==1},{'greenBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==2},{'yellowBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==3},{'redBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==4},{'pinkBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==5}]" v-if="item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].hasOwnProperty('timeFrameRisk')">{{item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk}}</span>
                            </span>
                        </router-link>
                            <div class="flex-shrink-0" v-if="item.trader.profile.name">
                                <router-link :to="`/trader/${item.trader.profile.id}/trading?t=${Object.keys(item.trader.timeframeStats)[0]}&m=1`"><p class="medium f-14 mb-0 roiTestSmall" :title="item.trader.profile.name" v-if="item.trader.profile.name">{{item.trader.profile.name}}</p></router-link>
                            </div>
                        </div>
                        <p class="mb-0 px-1 bold d-inline-flex" :class="ROIClass(item)" v-if="item.trader.timeframeStats && item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].hasOwnProperty('annualizedRorBasedRoi')">{{item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi >= 0 ? '+' : ''}}{{ parseFloat(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi).toFixed(2) || 0}}%</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 col-xl-3" v-if="store.topTrdaersList?.[2] && store.topTrdaersList[2].result && store.topTrdaersList[2].result.length">
                <div class="card-body vueCard element">
                    <div class="card-header bg-white flex-between">
                        <div class="d-flex align-items-center">
                            <p class="f-20 medium mb-0">
                                <!-- <v-lazy-image class="me-2" src="/assets/images/Icons-large.png" alt="IconArrow" /> -->
                                <span class="elips" :title="store.topTrdaersList[2].name"> {{store.topTrdaersList[2].name}}</span>
                            </p>
                            <div class="tooltipbutton mt-0 d-flex ms-2">
                            <span class="tooltiptext f-12">
                                <p class="mb-0 line-4 f-14">{{store.topTrdaersList[2].descirption }}</p>
                            </span>
                            <p class="f-12 d-flex align-items-center justify-content-center mb-0"> <vue-feather class="smallSize" type="alert-circle"></vue-feather></p>
                            </div>
                        </div>
                                <router-link class="roundArrow" :to="'/traders/list/' + store.topTrdaersList[2].filterId"><vue-feather
                                type="chevron-right"></vue-feather> </router-link>
                    </div>
                    <div class="flex-between px-3 mb-2" v-for="item,index in store.topTrdaersList[2].result.slice(0,5)" :key="index">
                        <div class="d-flex align-items-center ">
                            <router-link :to="`/trader/${item.trader.profile.id}/trading?t=${Object.keys(item.trader.timeframeStats)[0]}&m=1`">
                            <span class="me-3 position-relative">
                                    <v-lazy-image width="40" height="40" class="rounded-circle"
                                    :src="static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + item.trader.profile.zuluAccountId + '&ignore=false'"
                                    :alt="item.trader.profile.name" :title="item.trader.profile.name" />
                                <span :title="static_vars.riskTooltip" class="count position-absolute" :class="[{'blueBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==1},{'greenBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==2},{'yellowBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==3},{'redBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==4},{'pinkBtn':item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk==5}]" v-if="item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].hasOwnProperty('timeFrameRisk')">{{item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].timeFrameRisk}}</span>
                            </span>
                        </router-link>
                            <div class="flex-shrink-0" v-if="item.trader.profile">
                                <router-link :to="`/trader/${item.trader.profile.id}/trading?t=${Object.keys(item.trader.timeframeStats)[0]}&m=1`"><p class="medium f-14 mb-1 dark-text line-1 roiTestSmall" :title="item.trader.profile.name">{{item.trader.profile.name}}</p></router-link>
                                <!-- <p class="mb-0 f-11 neutralGrey medium" v-if=" item.trader.overallStats &&  item.trader.overallStats.followers">copiers {{item.trader.overallStats.followers || 0}}</p> -->
                            </div>
                        </div>
                        <p class="mb-0 px-1 bold d-inline-flex" :class="ROIClass(item)" v-if="item.trader.timeframeStats && item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].hasOwnProperty('annualizedRorBasedRoi')">{{item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi >= 0 ? '+' : ''}}{{ parseFloat(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi).toFixed(2) || 0}}%</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-12 col-xl-3">
                <div class="h-100 bg-white radius20 boxed border d-flex flex-column justify-content-evenly px-3 py-4 mx-0">
                    <div class="text-center">
                        <h5 class="mb-4 f-30 bold secondary">{{$t('top_traders.simulatorHeading')}}</h5>
                        <!-- <h6 class="f-20">Our Top Leaders</h6> -->
                        <p class="f-18 gray pb-3">{{$t('top_traders.simulatorText')}}</p>
                    </div>
                    <div class="d-inline-flex justify-content-center largeDek">
                        <router-link to="/simulation" class="button fillBtn smdButton zulu_btn px-md-5">{{$t('top_traders.simulatorButton')}}</router-link>
                    </div>
                    <!-- <div class="col-12 col-md-7">
                        <div class="mt-4 d-flex justify-content-center mt-md-0">
                            <v-lazy-image width="300" class="d-block mx-auto" src="/assets/images/hotpic.svg" alt="Hot Picks from Today" />
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
	import { myStore } from "@/store/pinia-store";
		export default {
			setup() {
				const store = myStore();
				return { store };
			},
			data() {
				return {
				};
			},
            methods:{
                ROIClass(item){
                    if(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi >= 0){
                        return 'greenView'
                    }else{
                        return 'redView'
                    }
                },
            }
	};
</script>